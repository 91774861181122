.menu-form-component {
  .form-content {
    max-width: 100%;
  }

  .menu-inputs {
    display: flex;
    flex-wrap: wrap;

    > :nth-child(1) {
      flex: 3 0 400px;
    }
    > :nth-child(2) {
      flex: 1 0 300px;
    }
  }

  .days {
    &.ion-invalid {
      border: 2px solid red;
    }
  }

  .day-component {
    .day-header {
      margin-top: 10px;
      background-color: #fff;
      display: flex;
      padding: 0 10px;

      .day-name, .day-date {
        flex: 1 0 150px;
      }

      .day-remove {
        flex: 0 0 50px;
        align-self: flex-end;
        margin: auto 0;

        ion-button {
          width: 100%;
        }
      }
    }

    .recipes {
      display: flex;
      flex-wrap: wrap;

      &.ion-invalid {
        border: 2px solid red;
      }
    }

    .add-recipe-card {
      flex: 1 0 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        flex: 0 0 50%;
        color: var(--ion-color-primary);
      }

      ion-icon {
        height: 25px;
        color: var(--ion-color-primary);
      }
    }
  }

  ion-list {
    background-color: #f3f3f3;
  }
}
