.shopping-list-page-component {
  > ion-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .category-header {
    flex: 1 0 300px;
    --ion-background-color: unset;

    ion-item-divider {
      background: #f9f9f9;
      --color: #888;
      text-align: center;
      color: #888;
      text-transform: uppercase;
      align-self: flex-end;
    }
  }

  .ingredient-item {
    &--None ion-item {

    }

    &--NotAvailable ion-item {
      text-decoration: line-through;
      --color: var(--ion-color-danger);
    }

    &--CrossedOut ion-item {
      text-decoration: line-through;
      --color: #999;
    }

    &--Bought ion-item {
      text-decoration: line-through;
      --color: var(--ion-color-success);
    }
  }


  .manual {
    text-align: justify;
    padding: 0 5px;
    margin: 5px 0;
  }

  .manual-green {
    color: var(--ion-color-success);
  }

  .manual-black {
    color: #000;
  }

  .manual-red {
    color: var(--ion-color-danger);
  }
}
