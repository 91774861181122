.badge-component {
    display: inline-block;
    padding: 0px 4px;
    margin: 3px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 5px;
    background-color: #777;

    &--clickable {
        cursor: pointer;
        position: relative;
        padding-right: 16px;
        ::after{
            content: 'x';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            background-color: #999;
            color: #fff;
            border-radius: 6px;
        }

        &:hover {
            background-color: #666;
        }
    }
}