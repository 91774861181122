.recipe-card-component {
  flex: 1 0 300px;

  ion-card {
    height: calc(100% - 20px);
    width: calc(100% - 20px);

    &::part(native) {
      height: 100%;
      width: 100%;
    }

    cursor: pointer;
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
  }

  .status-container {
    width: 100px;
    height: 30px;
    position: absolute;
    top: -3px;
    left: -38px;
    background: white;
    padding: 6px;

    transform: rotate(-45deg);

    z-index: 10;

    ion-icon {
      height: 100%;
      width: 100%;
      transform: rotate(45deg);
    }
  }

  .img-wrapper {
    flex: 5 0 50%;
    position: relative;

    .like-button {
      position: absolute;
      top: 0;
      right: 0;

      ion-button {
        margin: 0;
      }

      ion-icon {
        font-size: 25px;
        color: rgba(255, 255, 255, 0.8)
      }

      &.like-button-selected {
        ion-icon {
          color: rgba(235, 68, 90, 0.8);
        }
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .chips {
    flex: 1 0 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ion-card-title {
    padding: 10px 10px;
    flex: 100%;
  }

  .parent {
    flex: 100%;
    text-align: center;
    padding: 5px;
    background-color: #f9f9f9;
    color: #888;
    text-transform: uppercase;
    align-self: flex-end;
  }
}
