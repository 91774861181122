.checkbox-list-component {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ion-checkbox {
    flex: 1 0 120px;
    padding: 10px;
    font-size: 14px;

    &:hover {
      background: #f8fcf8;
    }
  }

  .fake-item {
    flex: 1 0 120px;
    height: 0;
  }
}