.menu-preview-component {
    .day-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding-left: 50px;
    }

    .day-container {
        margin-top: 5px;
        margin-bottom: 10px;
        position: relative;

        .tagline {
            height: 100%;
            font-size: 1.2em;
            position: absolute;
            width: 50px;
            z-index: 100;
            writing-mode: vertical-lr;
            text-align: center;
            vertical-align: middle;
            transform: rotate(180deg);
            top: 0px;
            left: 0px;
            background-color: white;
            color: #999;
            padding: 11px;
            text-transform: uppercase;
        }
    }
}
