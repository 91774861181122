.onboarding-component {
  text-align: center;
  position: relative;
  padding-top: 30px;

  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    margin: 10px;
  }

  .onboarding-image {
    margin: 0 auto;
    max-height: 300px;
    height: 300px;
  }

  p {
    max-width: 400px;
    height: 100px;
    margin: 10px auto;
  }
}