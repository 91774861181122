.menu-card-component {
  flex: 1 0 300px;

  ion-card {
    height: calc(100% - 20px);
    width: calc(100% - 20px);

    &::part(native) {
      height: 100%;
      width: 100%;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    position: relative;
  }

  ion-card-title {
    padding: 10px 10px;
    flex: 100%;
  }

  ion-card-subtitle {
    padding: 0 10px 10px 10px;
    flex: 100%;
  }
}
