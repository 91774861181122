@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-orb-component {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 70px;
  margin: auto;

  &.loading-orb-component--control{
    height: 90%;
    width: 75px;
  }

  .loading-spinner {
    flex: 0 0 50px;
    height: 50px;
    animation: spinner 1.5s linear infinite;
    padding: 10px;
  }

  .loading-spinner > * {
    width: 100%;
    height: 100%;
  }

  .text {
    span{
      width:100%;
      display: block;
    }
  }
}