.recipe-picker-component {
    display: flex;
    width: 100%;
    .recipe-picker-button {
        padding: 10px;
        user-select: none;
        flex: 1 0 100%;
        height: 100%;
        span {
            color: var(--ion-color-step-850, #262626);
            font-size: 16px;
            font-weight: 500;
            line-height: 1.1;
            width: 100%;
            display: block;
        }
        &:hover {
            background-color: #f8fcf8;
            cursor: pointer;
        }
        &.ion-invalid span {
            color: var(--ion-color-danger, #f44336);
        }
    }
}

ion-modal.recipe-picker-modal {
    --width: 100%;
    --height: 100%;
}
