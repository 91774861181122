ion-menu {
    --side-min-width: 330px;
    --side-max-width: 330px;

    &.md ion-content {
        --padding-start: 8px;
        --padding-end: 8px;
        --padding-top: 20px;
        --padding-bottom: 20px;
    }

    &.md ion-item {
        border-radius: 4px;
    }

    &.md ion-item.selected {
        --background: rgba(var(--ion-color-primary-rgb), 0.14);
    }

    &.md ion-item.selected ion-icon {
        color: var(--ion-color-primary);
    }

    ion-item.selected {
        --color: var(--ion-color-primary);
    }

    ion-content::part(scroll) {
        display: flex;
        flex-direction: column;
    }

    .main-section{
        flex: 1;
    }

    .profile-section{
        align-self: flex-end;
        width: 100%;

        .subscription-section {
            padding: 10px 0;

            &:hover {
                cursor: pointer;
                background: var(--ion-color-light);
            }
        }

        .avatar-section{
            display:flex;
            align-items: center;
            flex-direction: row;
            ion-avatar {
                flex: 0 0 50px;
                height: 50px;
            }

            ion-label {
                flex: 1;
                padding: 10px;
            }
            ion-button {
                flex: 0 0 50px;
                height: 50px;
                font-size: 20px;
            }
        }
    }
}
