.lazy-image-thumbnail {
    width: 100%;
    height: 100%;
    background-color: hsl(0, 0%, 95%);
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
        color: #fff;
        width: 50%;
        height: 50%;
    }
}