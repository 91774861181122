.start-page-component {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  h2 {
    flex: 1 0 100%;
    margin-left: 10px;
  }

  h4, h5 {
    margin-left: 10px;
  }
  h4, h5 {
    flex: 0 0 100%;
  }

  .menus {
    flex: 0 0 100%;

    .menu-card-component.add-menu {
      max-width: 300px;
      ion-button {
        height: 100%;
        width: 100%;
        margin: 0;
        min-height: 70px;
      }
    }
  }

  .recipes-inreview {
    flex: 1 0 300px;

    .recipe-card-component.add-recipe {
      max-width: 300px;

      ion-button {
        height: 100%;
        width: 100%;
        margin: 0;
        min-height: 70px;
      }
    }
  }

  .published-recipes {
    flex: 1 0 300px;
  }
}