.recipe-filter-form-component {
  ion-segment-button {
    --padding-start: 5px;
    --padding-end: 5px;
  }

  .filters-bar {
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    ion-searchbar {
      flex: 10 0 350px;
      padding-bottom: 0;
    }

    .buttons-container {
      display: flex;
      flex: 1;
      justify-content: space-evenly;
      align-items: center;
      
      ion-select {
        text-transform: uppercase;
        font-size: 14px;

        &::part(text)::first-letter {
          font-size: 18px;
          padding-right: 3px;
        }

        padding-left: 10px;
        --color: var(--ion-color-primary);
        flex: 0 0 100px;

        &::part(text) {
          color: var(--ion-color-primary);
        }

        &::part(icon) {
          color: var(--ion-color-primary);
          opacity: 1;
        }
      }

      ion-button {
        flex: 0 0 100px;
      }
    }
  }

  .filter-summary {
    display: flex;
    flex-wrap: wrap;

    .clear-filters {
      background-color: var(--ion-color-danger-shade);
    }

    .badge-component, .fake-item {
      flex: 1;
    }
  }
}

.recipe-filter-form-modal {
  .dropdown-with-toogle {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > ion-toggle {
      flex: 0 0 100px;
      font-size: 12px;
    }

    > div {
      flex: 3 0 300px;
    }
  }
}
