.recipe-menu-card-component {
    flex: 1 0 300px;

    ion-card::part(native) {
        height: 100%;
        width: 100%;
        min-height: 170px;
    }

    .container {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    .img-wrapper {
        flex: 0 0 160px;
    }

    .side-wrapper {
        flex: 1;
        padding: 0 4px;
    }

    .remove-button {
        flex: 1;
        align-self: flex-end;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    ion-card {
        height: calc(100% - 20px);
    }

    ion-card-title {
        padding: 10px 10px;
        width: 100%;
    }
}
