.shopping-list-component {
  ion-list-header ion-label {
    background-color: #f9f9f9;
    color: #666;
    padding: 10px 20px;
    text-transform: uppercase;
  }

  ion-item ion-label {
    padding: 0px 20px;
  }

}