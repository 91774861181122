.recipe-form-component {
    .form-content {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .time-inputs {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .cook-time-input,
        .prepare-time-input {
            flex: 1;
        }
        .overall-time-result {
            padding-right: 5px;
        }
    }

    .eaters-and-complexity {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .eaters-input,
        .complexity-input {
            flex: 1 0 300px;
            position: relative;

            .complexity-button-holder {
                position: absolute;
                top: 0;
                display: flex;
                width: calc(100% - 30px);
                height: calc(100% - 35px) ;
                margin: 20px 10px 10px 10px;
                ion-button {
                    flex: 1;
                    z-index: 10;
                    height: 100%;
                    margin-top: 0;
                    margin-bottom: 0;
                    color: grey;
                    &.complexity-active {
                        color: rgb(76, 183, 81);
                    }
                }
            }
        }
    }

    .description ion-textarea {
        min-height: 150px;
    }

    .images-container {
        padding-top: 10px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .image-item {
            flex: 1 0 180px;
            padding: 5px;
        }

        .image-item > .add-button {
            height: 100%;
            min-height: 150px;
        }
    }

    .ingredient {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .input-wrapper-component {
            padding: 5px 2px;
        }

        ion-reorder {
            align-self: center;
        }

        .ingredient-unit {
            flex: 3;
        }

        .ingredient-amount {
            flex: 2;
        }

        .ingredient-id {
            flex: 8;
        }

        .ingredient-group-item {
            flex: 1;
        }

        .ingredient-remove {
            align-self: center;
            flex: 0 0 25px;
            margin: 0px;
            height: 50px;
        }

        .ingredients-button-container {
            flex: 0 0 100%;
            display: none;
        }

        ion-reorder-group {
            display: none;
            flex: 0 0 100%;
        }

        ion-input {
            --padding-start: 6px;
            --padding-end: 6px;
        }

        ion-button {
            --padding-start: 4px;
            --padding-end: 4px;
        }
    }
}

ion-modal.image-upload-modal {
    --width: 100%;
    --height: 100%;
}

.no-ingredients-component {
    padding: 5px;
}