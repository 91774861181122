.recipe-preview-component {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: auto;
    gap: 10px;
    row-gap: 5px;

    .img-wrapper {
        flex: 10 0 300px;
    }

    img {
        width: 100%;
    }

    .thumbnails img {
        width: 100px;
        margin: 0 5px 5px 5px;
        min-height: initial;

        &:first-child {
            margin-left: 0;
        }

        &:hover {
            cursor: pointer;
            opacity: 0.9;
        }
    }

    .chips {
        display: flex;
        flex: 1 0 300px;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        gap: 5px;

        .chip-component {
            flex: 1 0 60px;
            background-color: #fff;
        }

        .full-width-chip {
            flex: 1 0 90%;
            background-color: #fff;
        }
    }

    .ingredients {
        flex: 1 0 300px;
        padding: 10px 10px 10px 0;
        background-color: #fff;
        ion-list-header ion-label {
            background-color: #f9f9f9;
            color: #666;
            padding: 10px 20px 10px 0;
            text-transform: uppercase;
        }
        ion-item {
            height: 38px;
            --min-height: 38px;
            ion-label {
                padding: 0px 20px 0px 0px;
                margin: 5px 0;
            }
        }
    }

    .recipe {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;

        padding: 10px 10px 10px 10px;
        flex: 10 0 300px;
        text-align: justify;
        background-color: #fff;

        >* {
            flex: 1 0 100%;
        }


    }

    .recipe-footer {
            flex: 1 0 100%;
            background-color: #fff;
        font-size: 0.9em;
        align-self: flex-end;
    }

    ion-list {
        --background-color: #fff;
    }
}
