.picker-modal {
  .selected-items-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 3px;

    >p {
      flex: 1 100%;
      margin: 3px;
    }

    .badge-component, .spacer{
      flex: 1 0 50px;
    }
  }
}