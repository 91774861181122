.controlled-date-picker-component {
    display: flex;
    width: 100%;

    ion-item {
        flex: 1 0 0;
    }

    .pickers-container {
        display: flex;
        flex: 1 0 0;
        flex-direction: row;
        justify-content: left;

        ion-datetime-button {
            flex: 0 0 150px;
        }
    }
}
