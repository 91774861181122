.image-upload-component {
    display: flex;
    flex-direction: column;
    .image-file-upload {
        flex: 1;
        padding: 5px;
        input:focus + label {
            outline: 1px solid #000;
            outline: -webkit-focus-ring-color auto 2px;
        }
        input:hover + label,
        input:focus + label {
            background: #5abd5f;
        }
        label {
            font-size: 14px;
            width: 200px;
            height: 30px;
            border-radius: 5px;
            background: var(--ion-color-primary, #3880ff);
            box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
        }
        .file {
            opacity: 0;
            width: 0.1px;
            height: 0.1px;
            position: absolute;
        }
    }

    .image-container {
        flex: 1;
    }
}
