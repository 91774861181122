.form-content {
    max-width: 1200px;
    background: #fff;
    margin: auto;
    padding: 10px;
}

.page-component {
    ion-content {
        --background: #f3f3f3;
    }

    ion-footer {
        padding: 5px;
        background-color: white;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        ion-button {
            padding: 2px 5px;
        }
    }

    @media screen and (max-width: 799px) {
        ion-button {
            padding: 0;
        }
    }

    @media screen and (max-width: 599px) {
        .title-with-buttons {
            display: none;
        }
    }
}
.flex-break {
    flex-basis: 100%;
    height: 0;
}

ion-modal {
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.3) !important;
}

