.input-wrapper-component {
    padding: 10px 3px;
    display: flex;

    ion-input {
        min-height: 38px !important;
    }

    ion-textarea, ion-input {
        --border-color: var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13)))) !important;
        font-size: 14px;
    }

    &.ion-invalid {
        ion-textarea, ion-input {
            min-height: 40px !important;
    
            --border-color: #f00 !important;
            --hightlight-color: #f00 !important;
            color: #f00;
        }
    }
}