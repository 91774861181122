.chip-component {
    width: calc(100% - 8px);
    padding: 2px 0 2px 8px;
    margin: 4px 4px 0 0;
    color: #999;
    background-color: #f9f9f9;

    strong {
        color: #666;
    }

    &--clickable {
        cursor: pointer;
    }
}
