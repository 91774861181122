.ingredient-form-component {
  padding: 5px;
  p {
    margin: 5px 0px;
  }

  .unit-translations {
    display: flex;
    flex-wrap: wrap;
    .input-wrapper-component {
      flex: 1 0 40%;
    }
    span {
      flex: 0 0 100%;
      color: #777;
      font-size: 0.9em;
    }
  }

  .unit-translations-remove {
    align-self: center;
    flex: 0 0 25px;
    height: 35px;
    margin: 0;
  }
}