.register-component {
  max-width: 600px;
  margin: 0 auto;
  padding: 10px;
  background-color: white;

  .terms-and-conditions {
    font-size: 11px;
    flex-wrap: wrap;
    a {
      padding-left: 5px;
    }
  }
}
