.menu-add-form-component{
    .period-inputs {
        display: flex;
        flex-direction: row;
        
        > * {
            flex: 1 0 200px;
        }
    }

    .form-content {
        margin: 10px auto;
    }
}